@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .skeleton {
    z-index: 10;
    border-radius: 0px;
    animation: shimmer 2s infinite;
    background: linear-gradient(to right, rgb(220, 220, 220) 4%, #ebebeb 25%, rgb(220, 220, 220)36%);
    //background: linear-gradient(to right, rgb(195, 195, 195) 4%, #ebebeb 25%, rgb(195, 195, 195)36%);
    background-size: 1000px 100%;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  padding: 0;
  -webkit-backspace-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background-color: var(--primary-color);
  //overflow: hidden;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
  height: 100%;
}

app-default {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

svg {
  /*shape-rendering: optimizeSpeed;
  text-rendering: optimizeSpeed;*/
  display: block;

  * {
    display: block;
  }
}

* {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  font-family: 'Roboto', sans-serif;
  /*-webkit-user-select: none; 
  -ms-user-select: none; 
  user-select: none;*/
  letter-spacing: 0.5px;

  &::-webkit-scrollbar {
    display: none;
  }
}

:root {
  touch-action: pan-x pan-y;
  //touch-action: none;
  height: 100%;
  --primary-color: #051623;
  --red: #d4013f;
  --green: #01994d;
  --red-hover: rgb(170, 1, 52);
  --secondary-color: #0e1e2b;
  --secondary-color-hover: #1e415d;
}

.container {
  width: 1280px;
  max-width: 90%;
  display: block;
  margin: 0 auto;
}

/* LOADER */
#clock-holder {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clock-loader {
  --clock-color: white;
  --clock-width: 4rem;
  --clock-radius: calc(var(--clock-width) / 2);
  --clock-minute-length: calc(var(--clock-width) * 0.4);
  --clock-hour-length: calc(var(--clock-width) * 0.2);
  --clock-thickness: 0.2rem;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--clock-width);
  height: var(--clock-width);
  border: 3px solid var(--clock-color);
  border-radius: 50%;

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: calc(var(--clock-radius) * 0.25);
    width: var(--clock-thickness);
    background: var(--clock-color);
    border-radius: 10px;
    transform-origin: center calc(100% - calc(var(--clock-thickness) / 2));
    animation: spin infinite linear;
  }

  &::before {
    height: var(--clock-minute-length);
    animation-duration: 2s;
  }

  &::after {
    top: calc(var(--clock-radius) * 0.25 + var(--clock-hour-length));
    height: var(--clock-hour-length);
    animation-duration: 15s;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

/* LOADER */

/* NEW LOADER */
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #D7003F;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

/* HTML: <div class="loader"></div> */
.pdf-loader {
  width: 100%;
  height: 14px;
  border-radius: 20px;
  color: var(--red);
  border: 2px solid;
  position: relative;
  border-width: 0;
}
.pdf-loader::before {
  content: "";
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: l6 2s infinite;
}
@keyframes l6 {
    100% {inset:0}
}

/* RESPONSIVE CLASSES */

.only-mobile {
  display: none;

  @media screen and (max-width: 600px) {
    display: block;
    ;
  }
}

.from-tablet {
  display: none;

  @media screen and (min-width: 601px) {
    display: block;
    ;
  }
}

.from-desktop {
  display: none;

  @media screen and (min-width: 1000px) {
    display: block;
    ;
  }
}

/* RESPONSIVE CLASSES */


/* MOBILE PINCHZOOM */
@media screen and (max-width: 600px) {
  html {
    &.stadium-view {
      overflow: hidden;

      main {
        max-height: -webkit-fill-available;
        overflow: hidden;
        padding: 0 !important;
        display: flex;
        flex-direction: column;

        app-stadium {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }

      }

      #informations {
        margin-top: 55px;
      }

      #stadium-holder {
        justify-content: space-between;
      }

      #outer-holder {
        /*height: calc(100% - 82px);*/
        /*height: calc(100% + 293px);*/
        flex-grow: 1;

        #tickets.stadium-tickets {
          height: 82px !important;
        }
      }
    }
  }

}

.sentry-error-embed-wrapper {
  display: none !important;
}